import React,{useRef,useState,useEffect} from "react"
import ContactUs from './ContactUs';
import { Row, Col, Container} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

import '../assets/style.css';

export default function({data}) {
    const pageData = data?.Cards

    if (!pageData) return <></>;

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
       return builder.image(source)
    }
    
    const tabList = useRef(null);
    const [isMobile, setIsMobile] = useState(getScreenSize())
    const [modalShow, setModalShow] = useState(false);
    const [selectedTab,setSelectedTab] = useState(0);

    useEffect(() => {
        if(isMobile){
            const tab = tabList.current.querySelector(".tab-list");
            if(!tab) return
            const containerWidth = tab.offsetWidth;
            const {offsetWidth:tabWidth,offsetLeft:tabLeft} = tab.querySelector(".react-tabs__tab--selected");

            const value = (containerWidth / 2) - tabLeft - (tabWidth / 2);

            tab.style.transform = `translateX(${value}px)`;

        }   
    },[selectedTab]);


    const handleResize = () => {
        setIsMobile(getScreenSize());
        if(!getScreenSize()){
            const tab = tabList.current.querySelector(".tab-list");
            tab.style.transform = `translateX(0px)`;
        }
    }
    
    function getScreenSize() {
        if (typeof window !== "undefined") {
        if (window.matchMedia("(min-width: 992px)").matches) {
            return false
        } else {
            return true
        }
        }
    }

   useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])

    
    return (
        <div className="mshs-services">  
            <Container>  
                <Tabs onSelect={(index) => setSelectedTab(index)}>
                    <Row> 
                        <Col xs={12} md={12} lg={12}>
                            <div className="content-box"  ref={tabList}>
                                <p className="title">{data?.title}</p> 
                                <TabList className="tab-list">
                                    {
                                        pageData.map((pageData,index) =>
                                            <Tab key={index}>
                                                <div className="box">
                                                    <img src={urlFor(pageData.logoImage.asset).url()}/>
                                                </div>
                                                <p>{pageData.title.split(" ")[0]}</p>
                                            </Tab> 
                                        )
                                    }
                                </TabList>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            {   
                                pageData.map((pageData,index) =>
                                    <TabPanel key={index} className="tab-panels">
                                        <img src={urlFor(pageData.bannerImage.asset).url()} alt="tabs alt" className="service-banner"/>
                                        <div className="card_lower">
                                            <div className="text_cnt">
                                                <div className="box_cnt">
                                                    <div className="box">
                                                        <img src={urlFor(pageData.logoImage.asset).url()} alt="logo alt"/>
                                                    </div>
                                                    <h2 className="title-mobile">{pageData.title}</h2>
                                                </div>
                                                <div>
                                                    <h2 className="title">{pageData.title}</h2>
                                                    <p className="description">{pageData.description}</p>
                                                </div>
                                            </div>
                                            <button onClick={() => setModalShow(true)}>{pageData.btnText}</button>
                                        </div>
                                    </TabPanel> 
                                )
                            }
                        </Col>
                    </Row> 
                </Tabs>
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>  
    )
}