import React from "react"
import '../assets/style.css';
import { Row, Container, Col, Figure, Button} from 'react-bootstrap';
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'


import ServiceSolution from '../components/services/service-solution';

export default function({data}) {
    const pageData = data?.Cards

    if (!pageData) return <></>;

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
      return builder.image(source)
    }

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="wwo-po">  
            <Container>
                <h2 className="wwo-po_section-title">{data.title}</h2>
            </Container>
            <Container> 
                <div className="wwo-po_content">
                   <Row>
                        <Col className="wwo-po_col" xs={12} md={6} lg={6}>
                            {pageData.slice(0, 2).map((pageData) =>{
                                return <ServiceSolution
                                image={urlFor(pageData.image.asset).url()}
                                title={pageData.title}
                                desc={pageData.description}
                                />
                            })}
                        </Col>
                        <Col className="wwo-po_col" xs={12} md={6} lg={6}>
                            {pageData.slice(2, 4).map((pageData) =>{
                                return <ServiceSolution
                                image={urlFor(pageData.image.asset).url()}
                                title={pageData.title}
                                desc={pageData.description}
                                />
                            })}
                        </Col>
                   </Row>
                </div>
            </Container>
            <Container> 
                <div className="wwo-po_section-header">
                    <div className="wwo-po_head">
                        <h2 className="wwo-po_title">{pageData[4].title}</h2> 
                        <span className="wwo-po_desc">{pageData[4].description}</span>
                    </div>
                    <div className="wwo-po_illustration">
                        <img className="illustration" src={urlFor(pageData[4].image.asset).url()} alt="Illustration" />
                    </div>
                    <div className="wwo-po_illustration mobile">
                        <img className="illustration" src={urlFor(pageData[4].image.asset).url()} alt="Illustration" />
                    </div>
                </div>
            </Container>
        </div>
    )
}
