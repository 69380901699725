import React from "react"
import '../assets/style.css';
import { Row, Container, Col, Figure, Button} from 'react-bootstrap';
import ServiceSolution from '../components/services/service-solution';
import CXProduct from '../assets/images/services/cx-product.png';
import CXExperience from '../assets/images/services/cx-experience.png';
import CXInsight from '../assets/images/services/cx-insight.png';
import CXStrategy from '../assets/images/services/cx-strategy.png';
import CXUiux from '../assets/images/services/cx-uiux.png';
import ServicesContactUs from "./services/services-contact-us";
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

export default function({data}) {
    const pageData = data?.Cards

    if (!pageData) return <></>;

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
      return builder.image(source)
    }

    return (
        <div className="wwo-dcm"> 
            <Container>
                <h2 className="wwo-dcm_section-title">{data.title}</h2>
            </Container>
            <Container>
                <Row>
                <div className="wwo-dcm_content">
                    <Col className="wwo-dcm_col" xs={12} md={6} lg={6}>
                        {pageData.slice(0, 2).map((pageData) =>{
                            return  <ServiceSolution
                            image={urlFor(pageData.image.asset).url()}
                            title={pageData.title}
                            desc={pageData.description}
                            />
                        })}
                    </Col>
                    <Col className="wwo-dcm_col" xs={12} md={6} lg={6}>
                        {pageData.slice(2, 4).map((pageData) =>{
                            return  <ServiceSolution
                            image={urlFor(pageData.image.asset).url()}
                            title={pageData.title}
                            desc={pageData.description}
                            />
                        })}
                    </Col>
                </div>
                </Row>
            </Container>
            <Container> 
                <div className="wwo-dcm_section-header">
                    <div className="wwo-dcm_head">
                        <h2 className="wwo-dcm_title">{pageData[4].title}</h2> 
                        <span className="wwo-dcm_desc">{pageData[4].description}</span>
                    </div>
                    <div className="wwo-dcm_illustration">
                        <img className="illustration" src={urlFor(pageData[4].image.asset).url()} alt="Illustration" />
                    </div>
                    <div className="wwo-dcm_illustration mobile">
                        <img className="illustration" src={urlFor(pageData[4].image.asset).url()} alt="Illustration" />
                    </div>
                </div>
            </Container>
        </div>
    )
}


