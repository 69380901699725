import React from 'react';
// import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Container, Button} from 'react-bootstrap';
import TECaseStudy from '../../assets/images/services/te-case-study.png';
import { sanityClient } from '../../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

export default function({data}) {
const {slug, spaceTop, title, titleColor, category, image, btnText, btnImage, backgroundColor, article} = data

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

    return (
        <div className={`services-case-study-container ${spaceTop ? 'space-top' : ''}`}>
            <Container>
                <div style={{backgroundColor: backgroundColor.hex}} className={`services-case-study ${slug}`}>
                    <div className='services-case-study-text'>
                        {category && <p style={{color: slug === 'technology-engineering' && 'white'}} className='case-study-category'> {category}</p>}
                        <h2 style={{color: titleColor.hex}}>{title}</h2>
                        <Button href={`/work/${article.csSlug}`}>
                            {btnText} <img src={urlFor(btnImage.asset).url()} alt="arrow right" />
                        </Button>
                    </div>
                    <div className='services-case-study-image'>
                        <img src={urlFor(image.asset).url()} alt="Case Study" />
                    </div>
                </div>
            </Container>
        </div>
    )
};
