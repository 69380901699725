import React, {useState } from 'react';
import { Container } from 'react-bootstrap';
import { sanityClient } from '../../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'


export default function({data}) {
    const pageData = data?.Cards

    if (!pageData) return <></>;

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
        return builder.image(source)
    }

    return (
        <div className='services-technologies-list'>
            <Container>
                <div className='services-digital-experiences'>
                    {pageData.map((pageData, index) => {
                        return (
                            <a href={pageData.href} className={`services-digital-experience`}>
                                <div className='services-digial-experience-image'>
                                    <img src={urlFor(pageData.image.asset).url()} alt="Digital Experience" />
                                </div>
                                <h3 >
                                    {pageData.title}
                                </h3>
                            </a>
                        )
                    })}
                </div>
            </Container>
        </div>
    )
}
