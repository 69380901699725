import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col, Figure, Button} from 'react-bootstrap';
import ServiceSolution from '../components/services/service-solution';
import ServicesContactUs from "./services/services-contact-us";
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

export default function({data}) {
    const pageData = data?.Cards

    if (!pageData) return <></>;

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
      return builder.image(source)
    }
    // const data = useStaticQuery(query)
    // const {allSanityWhatWeOfferInWebAndAppDevelopment:{
    //     nodes:wwowad
    // }} = data
    return (
    <div className="wwo-wad">  
        <Container>
            <h2 className="wwo-section-title"> We offer services in </h2>
        </Container>
        <Container> 
            <div className="wwo-content">
                <div className="wwo-head">
                    <h2 className="wwo-title">{pageData[0].title}</h2> 
                    <span className="wwo-desc">{pageData[0].description}</span>
                </div>
                <div className="what-we-offer-illustration">
                    <img className="illustration" src={urlFor(pageData[0].image.asset).url()} alt="Illustration" />
                </div>
                <div className="what-we-offer-illustration mobile">
                    <img className="illustration" src={urlFor(pageData[0].image.asset).url()} alt="Illustration" />
                </div>
            </div>
        </Container>
        <Container>
            <Row>
            <div className="wwote-solutions">
                <Col className="wwote-col" xs={12} md={6} lg={6}>
                    {pageData.slice(1, 3).map((pageData) =>{
                        return <ServiceSolution
                        image={urlFor(pageData.image.asset).url()}
                        title={pageData.title}
                        desc={pageData.description}
                        />
                    })}
                </Col>
                <Col className="wwote-col" xs={12} md={6} lg={6}>
                    {pageData.slice(3).map((pageData) =>{
                        return <ServiceSolution
                        image={urlFor(pageData.image.asset).url()}
                        title={pageData.title}
                        desc={pageData.description}
                        />
                    })}
                </Col>
            </div>
            </Row>
         </Container>
    </div>
    )
}



