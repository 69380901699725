import React from "react"
import '../assets/style.css';
import { Row, Container, Col, Button } from 'react-bootstrap';
import wwoteIllustration from '../assets/images/services/wwote-illustration-1.png';
import wwoteIllustrationMobile from '../assets/images/services/wwote-illustration-mobile.png';
import wwoteHeadingBGVisual from '../assets/images/services/wwote-bg-visual.png';
import solutionDeployedSuccessfully from '../assets/images/services/wwote-deployed-succesfully.png';
import solutionApis from '../assets/images/services/wwote-apis.png';
import solutionAnalytics from '../assets/images/services/wwote-analytics.png';
import solutionTechnology from '../assets/images/services/wwote-technology-architecture.png';
import ServiceSolution from '../components/services/service-solution';
import ServicesContactUs from "./services/services-contact-us";
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

export default function({data}) {
  const pageData = data?.Cards

  if (!pageData) return <></>;

  const builder = imageUrlBuilder(sanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

    return (
        <div className="wwo-te">  
          <Container> 
            <div className="wwo-content">
                <div style={{backgroundImage: `url(${urlFor(pageData[0].backgroundImage.asset).url()})`}} className="wwo-head">
                    <h2 className="wwo-title">{pageData[0].title}</h2> 
                    <span className="wwo-desc">{pageData[0].description}</span>
                </div>
                <div className="what-we-offer-illustration">
                    <img className="illustration" src={urlFor(pageData[0].image.asset).url()} alt="Illustration" />
                </div>
                <div className="what-we-offer-illustration mobile">
                    <img className="illustration" src={urlFor(pageData[0].secondImage.asset).url()} alt="Illustration" />
                </div>
            </div>
         </Container>
         <Container>
            <Row>
            <div className="wwote-solutions">
                <Col className="wwote-col" xs={12} md={6} lg={6}>
                    {pageData.slice(1, 3).map((pageData, id) =>{
                        return <ServiceSolution 
                        image={urlFor(pageData.image.asset).url()}
                        title={pageData.title}
                        desc={pageData.description}
                        className={id === 0 ? 'extra-margin' : ''}
                        />
                    })}
                </Col>
                <Col className="wwote-col" xs={12} md={6} lg={6}>
                    {pageData.slice(3).map((pageData, id) =>{
                        return <ServiceSolution 
                        image={urlFor(pageData.image.asset).url()}
                        title={pageData.title}
                        desc={pageData.description}
                        className={id === 1 ? 'extra-margin' : ''}
                        />
                    })}
                </Col>
            </div>
            </Row>
         </Container>
        </div>
    )
}
