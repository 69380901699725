import React from 'react';
import { Container, Button } from 'react-bootstrap';
import ServicesContactUs from './services-contact-us';
import endtoEndVideo from '../../assets/images/services/cx-gif.webm';
import endtoEndVideoThumbnail from '../../assets/images/services/end-to-end-thumbnail.png';
import ContactUs from '../ContactUs';

export default ({data}) => {
    const {title, paragraph, btnText, webm, textandimage, textcontainer, gifcontainer} = data

    const [modalShow, setModalShow] = React.useState(false);

    const buildFileUrl = (reference) => {
        if (!reference) return '';

        const id = reference.split("file-")[1].replaceAll("-", ".");
        const url = `https://cdn.sanity.io/files/${process.env.GATSBY_SANITY_PROJECT_ID}/${process.env.GATSBY_SANITY_DATASET}/${id}`;
        return url;
    }

    return (
        <div className='cx-container'>
            <Container>
                <div className={textandimage}>
                    <div className={textcontainer}>
                        <h2>{title}</h2>
                        <p>{paragraph}</p>
                        <Button   
                                className="contact-us-btn services-btn"
                                onClick={() => setModalShow(true)} 
                        > {btnText} </Button>
                    </div>
                    <div className={gifcontainer}>
                        <video loop={true} autoPlay="autoplay" muted>
                            <source src={buildFileUrl(webm?.asset?._ref)} type="video/webm"></source>
                        </video>
                    </div>
                </div>
                <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
};