import React, { useState } from "react"
import { Row, Col, Container,Button} from 'react-bootstrap';
import ContactUs from "./ContactUs";
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

import '../assets/style.css';
export default function({data}) {
    const {title, paragraph, btnText, image, bannercolor} = data

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
      return builder.image(source)
    }

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className={`${bannercolor} single-service-banner`}>  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">{title}</h1>
                        <p className="paragraph">{paragraph}</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        >{btnText}</Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img"><img src={urlFor(image.asset).url()} alt={`${title} banner`} /></Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>  
        </div>
    )
}
