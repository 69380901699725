import React from "react";
import ServicesBanner from '@components/services/servicesBanner';
import HomepageCarousel from "@components/HomepageCarousel";
import WWODCM from "@components/WWODCM";
import ServicesPageBanner from '@components/ServicesPageBanner';
import ServicesCaseStudy from "@components/services/services-case-study";
import ServicesTextImage from "@components/services/services-text-and-image";
import BookMeet from '@components/CtaMeet'
import ServicesContactUs from "@components/services/services-contact-us";
import WhatWeOfferInWebAndAppDevelopment from "@components/WWOWAD";
import SpellAndSell from "@components/SpellAndSell";
import WhatWeOfferInPersonalizationAndOptimization from "@components/WWOPO";
import WhatWeOfferTechnologyEngineering from "@components/WWOTE";
import ServicesBannerTech from "@components/services/ServicesBannerTech";
import ServicesTextContainer from "@components/services/services-text-container";
import ServicesTechList from "@components/services/services-technologies-list";
import Platforms from "@components/Platforms";
import ManagedSharedContent from "@components/MSHSContent";
import ManagedSharedServices from "@components/MSHSServices";
import MarginModule from "@components/MarginModule";

const _module2Component = {
    managedSharedServicesImageAndContentBox: ServicesBanner,
    articleHomepage: HomepageCarousel,
    whatWeOfferDcm: WWODCM,
    servicesPageBanner: ServicesPageBanner,
    servicesTextImage: ServicesTextImage,
    servicesCaseStudies: ServicesCaseStudy,
    bookMeet: BookMeet,
    servicesContactUs: ServicesContactUs,
    whatWeOfferInWebAndAppDevelopment: WhatWeOfferInWebAndAppDevelopment,
    spellandSell: SpellAndSell,
    whatWeOfferInPersonalizationAndOptimization: WhatWeOfferInPersonalizationAndOptimization,
    whatWeOfferTechnologyEngineering: WhatWeOfferTechnologyEngineering,
    servicesBannerTech: ServicesBannerTech,
    servicesTextContainer: ServicesTextContainer,
    servicesTechList: ServicesTechList,
    platforms: Platforms,
    managedSharedContent: ManagedSharedContent,
    managedSharedServices: ManagedSharedServices,
    marginModule: MarginModule,
};

const Module = ({ moduleData, type }) => {
    const RequestedModule = _module2Component[type];
    if (RequestedModule) return <RequestedModule data={moduleData} />;

    return <span>{type}</span>;
};

export default Module;