import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export default function({data}) {
    // const {title, description} = data

    return (
        <Container>
            <Row>
                <Col xs={12} lg={12} md={12}>
                    <div className='services-text-container'>
                        {data?.title && (
                            <h2> { data?.title } </h2>
                        )}
                        {data?.description && (
                            <p> { data?.description }</p>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
