import React from 'react';
import {Container, Button, Card, Row, Col } from "react-bootstrap";
import ContactUs from './ContactUs';
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

const MSHSContent = ({data}) => {

 const builder = imageUrlBuilder(sanityClient)

 function urlFor(source) {
    return builder.image(source)
 }

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="mshs-content">
        <Container>
            <Row>
                <Col xs="12" md="12" lg="4">
                    <div className='mshs-content-box'>
                        <h2>{data?.title}</h2>
                        <p>{data?.paragraph}</p>
                        <button onClick={() => setModalShow(true)}>{data?.btnText}</button>
                    </div>
                </Col>
                <Col xs="12" md="12" lg="8"  className='mshs-content-image'>
                    <img src={urlFor(data?.image.asset).url()} alt="MSHS circle"/>
                </Col>
            </Row>
            <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    </div>
  )
}

export default MSHSContent