import React from 'react'
import { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Button, Col, Container, Row, Modal } from 'react-bootstrap'
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'

export default function({data}) {
  const {title, description, aHref, aText, image, logo} = data
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const builder = imageUrlBuilder(sanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

    return (
      <Container>
        <div className="cta-meet">
            <div className="cta-meet-container"> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="cta-text">
                        <h1 className="cta-title">{title}</h1>
                        <p className="cta-description">{description}</p>
                        <Button className="primary-btn-s meet-sogody" onClick={handleShow}>{aText} <img src={urlFor(logo.asset).url()} alt="calendly logo" className="calendly-img" /></Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="cta-img">
                        <img src={urlFor(image.asset).url()} alt="grow manager" />
                    </Col>
                </Row>
            </div>
        </div>
        <Modal show={show} className="meet-modal">
          <Modal.Header>
            <Button variant="close-btn" onClick={handleClose}>
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <iframe src={aHref} width="100%" height="100%"  id="tsPagT5ZKxjYrsgLoU1G_1688724231324" scrolling="yes"></iframe>
          </Modal.Body>
        </Modal>
      </Container>
    )
}
