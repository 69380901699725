import React from 'react';

const Solution = ({ image, title, desc, className }) => {
    return (
        <div className={`solution ${className}`}>
            <div className="solution-image">
                <img src={image} alt="Solution" />
            </div>
            <div className="solution-content">
                <h2 className="solution-title"> {title} </h2>
                <p> {desc} </p>
            </div>
        </div>
    )
};

export default Solution;