import React from "react";
import { Helmet } from "react-helmet";
import Module from "../components/modules";
import Layout from "../components/Layout";

const RenderModules = ({ modules }) => {
	if (!modules) return;

	return modules?.map((module) => <Module key={module._key} type={module._type} moduleData={module} />);
};

const BaseLayout = (props) => {
	const { meta, handle, children } = props;

	return (
		<Layout >
			<MetaTags meta={meta} handle={handle} />
			{children}
		</Layout>
	);
};

const MetaTags = (props) => {
	if (!props) return <></>;
	const { meta, handle } = props
	const { title, description, image, noIndex } = meta;
	const { current } = handle

	return (
		<Helmet>
			<html rel="alternate" href={process.env.GATSBY_SITE_URL} lang={process.env.GATSBY_COUNTRY} />
			<title>{`${title}`}</title>
			<link rel="canonical" href={`${process.env.GATSBY_SITE_URL}${current}`} />

			<meta property="og:site_name" content="Sogody"></meta>
			<meta property="og:url" content={`${process.env.GATSBY_SITE_URL}${current}`}></meta>
			<meta property="og:title" content={title}></meta>
			<meta property="og:type" content="website"></meta>
			<meta name="description" property="og:description" content={description}></meta>
			<meta property="og:image" content={image}></meta>
			{noIndex && <meta name="robots" content="noindex" />}

			{/* Twitter Card*/}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
			<meta property="twitter:domain" content={process.env.GATSBY_SITE_URL} />
			<meta property="twitter:url" content={process.env.GATSBY_SITE_URL} />
		</Helmet>
	);
};

const Page = ({ pageContext }) => {
	const { meta, content, handle } = pageContext;
	
	return (
		<BaseLayout meta={meta} handle={handle}>
			{content.modules && <RenderModules modules={content.modules} />}
		</BaseLayout>
	);
};

export default Page;
