import React from 'react'
import '../assets/scss/main.scss'

const MarginModule = ({data}) => {
  return (
    <div className='height-spacing' style={data?.spacing && {height: data?.spacing}}>  
    </div>
  )
}

export default MarginModule
