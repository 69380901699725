import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ContactUs from '../ContactUs';

export default function({data}) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Container>
            <Row>
                <Col xs={12} md={12} lg={12}>
                   { <div className={`wwote-contact ${data?.extraStyling}`}>
                        {data?.title && <h2> { data?.title }</h2>}
                        <Button   
                                className="contact-us-btn"
                                onClick={() => setModalShow(true)} 
                    > {data?.btnText} </Button>
                    </div>}
                </Col>
            </Row>
            <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    )
}