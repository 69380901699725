import React from "react"
import '../assets/style.css';
import { Container } from 'react-bootstrap';
import { sanityClient } from '../../nodes/sanityClient'
import imageUrlBuilder from '@sanity/image-url'
import { Link } from "gatsby";


export default function({data}) {

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
      return builder.image(source)
    }

    return (
        <div className="spell"> 
         <Container>  
            <div style={{backgroundImage: `url(${urlFor(data?.backgroundImage.asset).url()})`}}  className="content-box">
                <div className="content-box-text">
                    <h2 className="content-box-title">{data?.title}</h2>
                    <p className="content-box-description">{data?.paragraph}</p>
                </div>
                <a href={data?.redirectUrl} target="_blank" className="content-box-button">
                    {data?.btnText}
                    <img src={urlFor(data?.btnImage.asset).url()} alt="Sparkle" />
                </a>
                <div className="content-box-illustration">
                    <img src={urlFor(data?.image.asset).url()} alt="Illustration" />
                </div>
            </div>
         </Container> 
        </div>
    )
}



